import React, { useEffect, useState, useContext, useRef } from "react";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Link, useHistory } from "react-router-dom";
import { CustomerContext } from "../layouts/Routes";
import Config from "../config/Config";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import Slider from "react-slick";
import SubscribeContainer from "../components/SubscribeContainer";

const TermsAndConditions = () => {
  const scrollRef = useRef(null);
  const { state, dispatch } = useContext(CustomerContext);
  const history = useHistory();

  // Scroll to view

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  // scroll to top when user click back button
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <>
      {/* <Header /> */}
      <main class="main pages" style={{ transform: "none" }} ref={scrollRef}>
        <div class="page-header breadcrumb-wrap">
          <div class="container">
            <div class="breadcrumb">
              <Link to="/">
                <i className="fa fa-home mr-5"></i>Home
              </Link>
              <span></span> Terms & Conditions
            </div>
          </div>
        </div>
        <div class="page-content pt-50" style={{ transform: "none" }}>
          <div class="container" style={{ transform: "none" }}>
            <div class="row" style={{ transform: "none" }}>
              <div
                class="col-xl-12 col-lg-12 m-auto"
                style={{ transform: "none" }}
              >
                <div class="row" style={{ transform: "none" }}>
                  <div class="col-lg-10 m-auto">
                    <div class="single-page pr-30 mb-lg-0 mb-sm-5">
                      <div class="single-header style-2">
                        <h2>Terms & Conditions</h2>
                      </div>
                      <div class="single-content mb-10">
                        <h4>TERMS OF USE</h4>
                        <p>
                          Please read the terms and conditions ("Terms and
                          Conditions") set out below carefully before ordering
                          any Goods or Services from this Website. By ordering
                          any Goods or Services from this Website, by phone, or
                          by our mobile applications, you agree to be bound by
                          these Terms and Conditions.
                        </p>
                      </div>

                      <div class="single-content mb-10">
                        <ol>
                          <li>
                            <h4 style={{ display: "inline" }}>Introduction</h4>
                            <ol>
                              <li>
                                The domain name www.thecakeinc.com (hereinafter
                                referred to as "Website") is owned by Creamy
                                Cloud Private Limited a company incorporated
                                under the Companies Act, 2013 with its
                                registered office at H.No. 6B , Bhawani Tower,
                                Kolkata 700102 , India (hereinafter referred to
                                as "TheCakeInc.").
                              </li>
                            </ol>
                          </li>

                          <li>
                            <h4 style={{ display: "inline" }}>Definitions</h4>
                            <ol>
                              <li>
                                “Agreement” is a reference to these Terms and
                                Conditions, any order, form and payment
                                instructions provided to you;
                              </li>
                              <li>
                                “Privacy Policy” means the policy displayed on
                                our Website which details how we collect and
                                store your personal data;
                              </li>
                              <li>
                                “you”, “your” and “yours” are references to you,
                                the person accessing this Website and ordering
                                any Goods or Services from the Website or from
                                any other channel provided by TheCakeInc.
                              </li>
                              <li>
                                “we”, “us”, “our”, and ” TheCakeInc. ” are
                                references to the TheCakeInc.;
                              </li>
                              <li>
                                “Goods”, “Items” is a reference to any goods
                                which we may offer for sale from our Website
                                from time to time;
                              </li>
                              <li>
                                “Service” or “Services” is a reference to any
                                service which we may supply and which you may
                                request via our Website;
                              </li>
                              <li>
                                “Participating supplier” is a third party, which
                                has agreed to co-operate with us to prepare
                                and/or deliver the Goods or Services.
                              </li>
                              <li>
                                “Food Delivery” is a reference to perishable
                                goods and to any form of delivery service, which
                                both are provided by our partners and for both
                                of which our partners take full responsibility.
                              </li>
                              <li>
                                “Website” is a reference to our Website
                                www.thecakeinc.com or our mobile applications on
                                which we offer our Goods or Services.
                              </li>
                            </ol>
                          </li>

                          <li>
                            <h4 style={{ display: "inline" }}>Ordering</h4>
                            <ol>
                              <li>
                                Any contract for the supply of Food Delivery
                                from this Website is between you and our
                                Participating Suppliers; for the supply of Goods
                                or Services from this website contact is between
                                you and TheCakeInc. You agree to take particular
                                care when providing us with your details and
                                warrant that these details are accurate and
                                complete at the time of ordering. You also
                                warrant that the credit or debit card details
                                that you provide are for your own credit or
                                debit card and that you have sufficient funds to
                                make the payment.
                              </li>
                              <li>
                                Food Delivery, Goods and Services purchased from
                                this Website are intended for your use only and
                                you warrant that any Goods purchased by you are
                                not for resale and that you are acting as
                                principal only and not as agent for another
                                party when receiving the Services.
                              </li>
                              <li>
                                Please note that some of our Goods may be
                                suitable for certain age ranges only. You should
                                check that the product you are ordering is
                                suitable for the intended recipient.
                              </li>
                              <li>
                                When ordering from this Website you may be
                                required to provide a phone number, e-mail
                                address and password. You must ensure that you
                                keep the combination of these details secure and
                                do not provide this information to a third
                                party.
                              </li>
                              <li>
                                We will take all reasonable care, in so far as
                                it is in our power to do so, to keep the details
                                of your order and payment secure, but in the
                                absence of negligence on our part we cannot be
                                held liable for any loss you may suffer if a
                                third party procures unauthorized access to any
                                data you provide when accessing or ordering from
                                the Website.
                              </li>
                              <li>
                                Any order that you place with us is subject to
                                product availability, delivery capacity and
                                acceptance by us and the Participating Suppliers
                                . When you place your order online, we will send
                                you an email to confirm that we have received
                                it. This email confirmation will be produced
                                automatically so that you have confirmation of
                                your order details. You must inform us
                                immediately if any details are incorrect. The
                                fact that you receive an automatic confirmation
                                does not necessarily mean that either we or the
                                Participating Suppliers will be able to fill
                                your order. Once we have sent the confirmation
                                email we will check availability and delivery
                                capacity.
                              </li>
                              <li>
                                If the ordered Food and delivery capacity is
                                available, the Participating Supplier will
                                accept the contract and confirm it to TheCakeInc
                                . If the details of the order are correct, the
                                contract for the Food Delivery, Goods or
                                Services will be confirmed by email or text
                                message(SMS).
                              </li>
                              <li>
                                The confirmation message/ email will specify
                                items ordered along with a receipt of payment.
                                3.9.If the Food Delivery and / or Goods are not
                                available or if there is no delivery capacity,
                                we will also let you know by email or text
                                message (SMS) or phone call.
                              </li>
                            </ol>
                          </li>

                          <li>
                            <h4 style={{ display: "inline" }}>
                              Prices and Payment
                            </h4>
                            <ol>
                              <li>
                                You agree to take particular care when providing
                                us with your details and warrant that these
                                details are accurate and complete at the time of
                                ordering. You also warrant that the credit or
                                debit card details that you provide are for your
                                own credit or debit card and that you have
                                sufficient funds to make the payment.
                              </li>
                              <li>
                                All prices listed on the Website are correct at
                                the time of publication and have been based on
                                input received from the supplier; While we give
                                great care to keep them up to date, the final
                                price charged to you can change at the time of
                                delivery based on the latest menu and prices. We
                                also reserve the right to alter the Goods or
                                Services available for sale on the Website.
                              </li>
                              <li>
                                All prices for delivery by TheCakeInc. or a
                                third party provider assigned by TheCakeInc. are
                                correct at the time of publication. However, we
                                reserve the right to alter these in the future
                              </li>
                              <li>
                                The total price for Food Delivery, Goods or
                                Services ordered, delivery charges and other
                                charges will be displayed on the Website when
                                you place your order. Full payment must be made
                                for all Goods dispatched and Services provided.
                                Payment has to be made online, e.g. via UPI, Net
                                Banking, Credit or Debit card.
                              </li>
                              <li>
                                In case of online payment, you must pay for your
                                order before it is delivered. Our website is
                                integrated with 3rd party payment gateways. We
                                do not store any card or banking details on our
                                website. For privacy policies and terms and
                                condition, please refer to the relevant payment
                                gateway's website.
                              </li>
                              <li>
                                TheCakeInc. never asks for any of your personal
                                bank details. Please do not share your personal
                                bank details like Card Number/Pin/CVV/OTP/Card
                                Expiry Date/UPI Pin, over Phone/Mail/SMS to
                                anyone.
                              </li>
                            </ol>
                          </li>

                          <li>
                            <h4 style={{ display: "inline" }}>Delivery</h4>
                            <ol>
                              <li>
                                Delivery periods quoted at the time of ordering
                                are approximate only and may vary. Goods will be
                                delivered to the address designated by you at
                                the time of ordering.
                              </li>
                              <li>
                                If delivery is done by its delivery partners, it
                                is the Participating supplier sole
                                responsibility to provide Food Delivery in a
                                timely manner.
                              </li>
                              <li>
                                In the case delivery is done by TheCakeInc. or a
                                third party delivery partner assigned by
                                TheCakeInc., we will give great care to deliver
                                in a timely manner. No responsibility is taken
                                for late delivery by TheCakeInc. in either case.
                              </li>
                              <li>
                                All orders are delivered by a reputable courier.
                                We and the delivery will make every effort to
                                deliver within the time stated. However, we will
                                not be liable for any loss caused to you by
                                ordering late.
                              </li>
                              <li>
                                All risk in the Goods and the Food Delivery
                                shall pass to you upon delivery.
                              </li>
                              <li>
                                If you fail to accept delivery of Goods at the
                                time they are ready for delivery, or we are
                                unable to deliver at the nominated time due to
                                your failure to provide appropriate
                                instructions, then such goods shall be deemed to
                                have been delivered to you and all risk and
                                responsibility in relation to such goods shall
                                pass to you.
                              </li>
                              <li>
                                Participating Suppliers, who will prepare your
                                order, aim to deliver the product to you at the
                                place and time of delivery requested by you in
                                your order. However, we shall not be liable to
                                you for any losses, liabilities, costs, damages,
                                charges or expenses arising out of late
                                delivery;
                              </li>
                              <li>
                                Please note that it might not be possible for us
                                to deliver to some locations. If this is the
                                case we or our partner supplier will inform you
                                using the contact details that you provide to us
                                when you make your order and arrange for
                                cancellation of the order or delivery to an
                                alternative delivery address;
                              </li>
                              <li>
                                The delivery of the items ordered will be
                                attempted only ONCE at the address as provided.
                                In the event the delivery is not executed due to
                                any reason whatsoever, the order shall be
                                considered complete, and re-delivery or a refund
                                will not be an option. The order will be deemed
                                executed and delivered in the following events:
                                Wrong address Recipient not available at
                                premises Premises locked Unreachable or
                                incorrect phone number of recipient Recipient
                                refused the products. Delivered the product at
                                the gate / reception / neighbourhood of the
                                premises Restricted entry of premises
                              </li>
                              <li>
                                Deliveries shall not be made on National
                                Holidays. Orders scheduled for National holidays
                                will be delivered on the next working day.
                              </li>
                              <li>
                                In case of floods/ Heavy Rains/ natural
                                calamity/ National Bandh/election dates/
                                curfews/traffic jams/festival times/regulated
                                traffic movements/ or any other impediments that
                                may restrict the delivery of the order.
                                TheCakeInc. reserves the right to reschedule the
                                delivery for another date.
                              </li>
                              <li>
                                No instructions will be entertained for any
                                request to change the date of delivery.
                              </li>
                            </ol>
                          </li>

                          <li>
                            <h4 style={{ display: "inline" }}>Cancellation</h4>
                            <ol>
                              <li>
                                Please refer to cancellation policy on our
                                website.
                              </li>
                            </ol>
                          </li>

                          <li>
                            <h4 style={{ display: "inline" }}>Linked Sites</h4>
                            <ol>
                              <li>
                                There may be a number of links on our Website to
                                third party Websites which may be of interest to
                                you. We do not represent the quality of the
                                Goods or Services provided by such third parties
                                nor do we have any control over the content or
                                availability of such sites. We cannot accept any
                                responsibility for the content of third party
                                Websites or the Services or Goods that they may
                                provide to you.
                              </li>
                            </ol>
                          </li>

                          <li>
                            <h4 style={{ display: "inline" }}>Complaints</h4>
                            <ol>
                              <li>
                                We take complaints very seriously and aim to
                                respond within 12 business hours. All complaints
                                should be addressed to support@thecakeinc.com ,
                                and we assure to assist if reported within 24
                                hours of delivery. Kindly note that the images
                                displayed on the website are indicative in
                                nature. Actual product may vary in shape or
                                design as per the availability.
                              </li>
                            </ol>
                          </li>

                          <li>
                            <h4 style={{ display: "inline" }}>
                              Limitation of Liability
                            </h4>
                            <ol>
                              <li>
                                Great care has been taken to ensure that the
                                information available on this Website is correct
                                and error free. We apologize for any errors or
                                omissions that may have occurred. Therefore, we
                                do not make any warranty whatsoever, whether
                                express or implied, relating to its accuracy.
                              </li>
                              <li>
                                By accepting these terms of use, you agree to
                                relieve us from any liability whatsoever arising
                                from your use of information from any third
                                party, or your use of any third party website,
                                or your consumption of any food or beverages
                                from a Participating Supplier.
                              </li>
                              <li>
                                We disclaim any and all liability to you for the
                                supply of the Food Delivery, Goods and Services
                                to the fullest extent permissible under
                                applicable law. This does not affect your
                                statutory rights as a consumer. If we are found
                                liable for any loss or damage to you such
                                liability is limited to the amount you have paid
                                for the relevant Goods or Services. We cannot
                                accept any liability for any loss, damage or
                                expense, including any direct or indirect loss.
                              </li>
                              <li>
                                We do not accept any liability for any delays,
                                failures, errors or omissions or loss of
                                information, viruses or other contamination or
                                destructive properties transmitted to you or
                                your computer system via our Website.
                              </li>
                              <li>
                                We shall not be held liable for any failure or
                                delay in performing Services or delivering Goods
                                arising owing to reasons beyond our control.
                              </li>
                              <li>
                                The products sold by us are provided for private
                                domestic and consumer use only. Accordingly, we
                                do not accept liability for any indirect loss,
                                consequential loss, loss of data, loss of income
                                or profit, loss of damage to property and/or
                                loss from claims of third parties arising out of
                                the use of the Website or for any products or
                                services purchased from us.
                              </li>
                              <li>
                                We have taken all reasonable steps to prevent
                                Internet fraud and ensure any data collected
                                from you is stored as securely and safely as
                                possible. However, we cannot be held liable in
                                the extremely unlikely event of a breach in our
                                secure computer servers or those of third
                                parties.
                              </li>
                              <li>
                                Offers are subject to TheCakeInc. discretion and
                                may be withdrawn at any time and without notice.
                              </li>
                            </ol>
                          </li>

                          <li style={{ marginBottom: "30px" }}>
                            <h4 style={{ display: "inline" }}>General</h4>
                            <ol>
                              <li>
                                All prices are in Indian Rupees and can be
                                converted to your local currency at the time of
                                payment, depending on the payment gateway
                                provider.
                              </li>
                              <li>
                                We may subcontract any part or parts of the
                                Services or Goods that we provide to you from
                                time to time without your consent or any
                                requirement to notify you.
                              </li>
                              <li>
                                We may alter or vary the Terms and Conditions at
                                any time without notice to you.
                              </li>
                              <li>
                                Payment must be made at the time of placing
                                order by bank transfer, credit / debit card or
                                at the time of delivery by cash (if available).
                                Failure to pay on time will result in the
                                cancellation of your order.
                              </li>
                              <li>
                                Please do not use or launch any automated system
                                or program in connection with our website or its
                                online ordering functionality;
                              </li>
                              <li>
                                Please do not collect or harvest any personally
                                identifiable information from the website, use
                                communication systems provided by the website
                                for any commercial solicitation purposes,
                                solicit for any reason whatsoever any users of
                                the website with respect to their submissions to
                                the website, or publish or distribute any
                                vouchers or codes in connection with the
                                website, or scrape or hack the website.
                              </li>
                              <li>
                                The Terms and Conditions together with the
                                Privacy Policy, any order form and payment
                                instructions constitute the entire agreement
                                between you and us. No other terms whether
                                expressed or implied shall form part of this
                                Agreement. In the event of any conflict between
                                these Terms and Conditions and any other term or
                                provision on the Website, these Terms and
                                Conditions shall prevail.
                              </li>
                              <li>
                                If any term or condition of our Agreement shall
                                be deemed invalid, illegal or unenforceable, the
                                parties hereby agree that such term or condition
                                shall be deemed to be deleted and the remainder
                                of the Agreement shall continue in force without
                                such term or condition.
                              </li>
                              <li>
                                These Terms and Conditions and our Agreement
                                shall be governed by and construed in accordance
                                with the laws of India. The parties hereto
                                submit to the exclusive jurisdiction of the
                                courts of India. The place of jurisdiction shall
                                be exclusively in Kolkata.
                              </li>
                              <li>
                                No delay or failure on our part to enforce our
                                rights or remedies under the Agreement shall
                                constitute a waiver on our part of such rights
                                or remedies unless such waiver is confirmed in
                                writing.
                              </li>
                              <li>
                                These Terms and Conditions and a contract(and
                                all non- contractual obligations arising out of
                                or connected to them) shall be governed and
                                construed in accordance with Indian Laws. Both
                                we and you hereby submit to the non - exclusive
                                jurisdiction of the Indian Courts. All dealings,
                                correspondence and contacts between us shall be
                                made or conducted in the English language.
                              </li>
                              <li>
                                While sharing my Phone no., Email and Name
                                during the registration or payment process, I
                                understand that TheCakeInc. can send
                                transactional and promotional SMS messages on my
                                registered phone number. I also understand that
                                TheCakeInc. can send transactional and
                                promotional Email messages on my registered
                                email as well.
                              </li>
                              <li>
                                As we will be processing the order post your
                                confirmation, it won't be possible to modify or
                                cancel the order once the telephonic
                                confirmation is done. 10.14 I understand that
                                TheCakeInc. can send transactional and
                                promotional SMS, Call, WhatsApp, RCS & Emails on
                                my registered email id & number.
                              </li>
                              <li>
                                In any case, if the order is placed by any
                                individual unethically from www.thecakeinc.com.
                                This will be considered as part of a fraud. The
                                company has the right to cooperate with
                                competent law enforcement agencies and to ask
                                them to take appropriate action whenever such
                                phenomena occur. Additionally, the company will
                                notify the police or other competent authority.
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <SubscribeContainer />
      <Footer />
    </>
  );
};

export default TermsAndConditions;
